<template>
    <div class="">
        <div class="card">
            <div class="bg-white card-header">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="form-row">
                            <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                            
                            <div class="col-md-8" v-if="isshowTable('ar_reg_date')">
                                <div class="form-group mb-0 d-flex">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="datePickerConfig.locale"
                                        :autoApply="datePickerConfig.autoApply"
                                        v-model="dateRange"
                                        :opens="'right'"
                                        :ranges="datePickerConfig.ranges"
                                        @update="updateValues"
                                    >
                                        <template v-slot:input="picker">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-auto col-lg-7">
                        <div class="form-row justify-content-end">
                            <div class="col-md-5">
                                <div class="lbl_status_pasien bg_red">
                                    <h3>
                                        <i class="icon-users2"></i>
                                        {{getQueue}} PASIEN ANTRI
                                    </h3>

                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="lbl_status_pasien bg_green">
                                    <h3>
                                        <i class="icon-user-check"></i>
                                        {{getAction}} DALAM TINDAKAN DOKTER FISIO
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-auto">
                                <a href="javascript:;" @click="$parent.openModalResume(filter.startDate,filter.endDate)" data-toggle="modal" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
                                    <h3><i class="icon-menu"></i></h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-row">
                            <div class="col-md-auto">
                                <div class="form-group mb-0">
                                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="form-group mb-0">
                                    <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                </div>
                            </div>

                            <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                <b-form-group class="mb-0">
                                    <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group class="mb-0">
                                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.process_status" :clearable="true" :options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                            <input class="form-control"
                                v-model="filter.search"
                                @input="doSearch"
                                placeholder="Ketik Nama/No. RM Pasien"
                            />
                            <div class="form-control-feedback">
                                <i class="icon-search4 text-indigo"></i>
                            </div>
                                
                            <b-button
                                class="ml-1 d-inline-flex align-items-center"
                                variant="outline-success"
                                id="resetBtn"
                                @click="doResetData()"
                            >Reset</b-button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="table-responsive sticky-table">
                <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Panggil Pasien</th>
                            <th v-if="isRegistration">No Antrian</th>
                            <th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
                            <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                            <th v-if="isshowTable('ars_name')">Status</th>
                            <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                            <th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
                            <th v-if="isshowTable('ap_code')">No. RM</th>
                            <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                            <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                            <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                            <th v-if="isshowTable('ap_usia')">Usia</th>
                            <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                            <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loadingTable">
                        <template v-for="(v,k) in dataReg" >
                            <tr :key="k" :class="v.ar_process_status == 'QUEUE' ? 'table-info' : v.ar_process_status == 'VOID' ? 'table-danger' : ''">
                                <td>{{k+1}}</td>
                                
                                <td class="text-center">
                                    <a v-if="v.ar_is_void !== 'Y' && v.ar_process_status !== 'ABSEN'" @click="requestPanggil(v,v.ar_is_called)" href="javascript:;" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip" title="Panggil Pasien" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Panggil Pasien"><i :class="v.ar_is_called !== 'Y' && (user.levelId == 7 || user.levelId == 1) && v.ar_status == sFisio ? 'icon-megaphone' : 'icon-eye'"></i></a>
                                    <span v-else> - </span>
                                </td>

                                <td v-if="isRegistration" >
                                    <small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
                                    <span class="badge bg-primary mb-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>
                                </td>

                                <td v-if="isshowTable('ar_reg_code')">
                                {{v.ar_reg_code||"-"}}
                                <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                <td v-if="isshowTable('ap_fullname')">
                                <span>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                                <p>{{v.ap_nik||"-"}}</p>
                                </td>
                                
                                <td v-if="isshowTable('ars_name')">
                                <span v-if="v.ar_process_status == 'QUEUE' && v.ar_status == sFisio" :class="`badge bg-warning`">Dalam Antrian</span>
                                <template v-else-if="v.ar_status == sPPA">
                                    <span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                    <span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                                    <span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'" :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                                    <span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'" :class="'badge bg-info text-white mb-1'">Dietisien</span>
                                </template>
                                <span v-else :class="`badge ${v.ars_color}`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                <span v-if="v.ar_is_belum_datang == 'Y'" :class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>
                                
                                <span v-if="v.arm_is_draft == 'Y'" :class="`badge badge-warning mt-1`">{{uppercaseWord('Draft')||'-'}}</span>
                                </td>

                                <td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>          
                                <td v-if="isshowTable('ar_reg_date')">
                                {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                <td v-if="isshowTable('ap_usia')">
                                {{v.ap_usia_with_ket||"-"}}
                                <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                </td>
                                <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                <td v-if="isshowTable('ar_penunjang')">
                                    <div v-if="v.ar_is_ppa_radiologi == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Radio</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_lab == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Lab</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_fisio == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Fisioterapi</span>
                                    </div>
                                    <div v-if="v.ar_is_ppa_dietisien == 'Y'" class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">Dietisien</span>
                                    </div>
                                    <span v-if="v.ar_is_ppa_radiologi !== 'Y' && v.ar_is_ppa_lab !== 'Y' && v.ar_is_ppa_fisio !== 'Y' && v.ar_is_ppa_dietisie !== 'Y'"> - </span>
                                </td>
                                <td>
                                    <div :id="`isAction${v.ar_id}`">
                                    <!--
                                    <a href="javascript:;" class="btn alpha-info border-info text-info-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)" v-if="moduleRole('request_edit') && v.arm_id && !v.arm_can_edit_by && !otherConditional(v)"><i class="icon-drag-left"></i></a>
                                    -->

								    <a href="javascript:;" @click="redirect(v)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Edit" 
                                    v-if="(v.arm_can_edit_by && v.arm_is_approve == 'Y' || otherConditional(v)) && v.arm_id"><i class="icon-pencil7"></i></a>
                                    </div>
                                    
                                    <span v-if="v.arm_id && !v.arm_can_edit_by && !otherConditional(v)"> - </span>
                                    <!--
                                    <span v-if="!((v.arm_can_edit_by && v.arm_is_approve == 'Y' || otherConditional(v)) && v.arm_id) && !(moduleRole('request_edit') && v.arm_id && !v.arm_can_edit_by && !otherConditional(v))"> - </span>
                                    -->

                                    <!--
                                    <a href="javascript:;" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Approval Edit" @click="approvalEdit(v)" 
                                    v-if="moduleRole('approval_edit') && v.arm_can_edit_by && v.arm_is_approve == 'N'"><i class="icon-check"></i></a>
                                    -->
							    </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-if="loadingTable">
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                    <tbody v-if="loadingInvinite">
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                    </tbody>
                    <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                        <tr>
                            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                Not Found Data
                            </th>
                        </tr>                
                    </tbody>                
                </table>
                <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-left"></i>
                    </a>
                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
        
        <b-modal 
            v-model="openFilter"
            :title="'Filter Kolom'"
            size="sm"
            ok-title="Terapkan"
            @ok="terapkan()"
        >
            <div class="row">
                <div class="col-md-12">
                <div class="wrap_line">
                    <div class="form-check p-0">
                        <label class="form-check-label d-flex">
                        <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                        Tampilkan Semua
                        </label>
                    </div>
                    <hr class="my-2">
                    <div class="pl-2">
                        <b-form-group>
                            <b-form-checkbox-group
                                id="checkbox-block"
                                v-model="selectedFilter"
                                :options="optionFilter"
                                name="filter"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal 
            v-model="openPersetujuan"
            :title="'Konfirmasi Ajukan Perubahan'"
            size="sm"
            body-class="p-2"
            hide-footer
        >
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                    <i class="icon-warning2"></i>
                </div>
                <h6 class="mb-3">Apakah Anda yakin untuk mengajukan perubahan pengkajian untuk pasien 
                {{rowEdit.ap_fullname || "-"}} ?</h6>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
                <button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Ya, Ajukan</button>
            </div>
        </b-modal>

        
        <validation-observer
            ref="VFormPanggil"
        >
        <b-modal 
            v-model="openPanggil"
            :title="'Konfirmasi Pemanggilan Pasien'"
            size="md"
            body-class="p-2"
            hide-footer
        >
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-megaphone"></i>
                </div>
                <h6 class="mb-0">Memanggil Pasien No. {{rowEdit.ar_reg_code}} atas Nama</h6>
                <h3 class="font-weight-semibold text-primary">{{rowEdit.ap_fullname||"-"}}</h3>
                <h6>Apakah pasien hadir?</h6>
                <b-form-group class="mt-3">
                  <b-form-radio-group
                    :options="Config.mr.StatusPanggil"
                    v-model="rowEdit.ar_is_called"
                  />
                </b-form-group>
                
                <div class="form-group text-left" id="patientNotPresenceInfo" v-if="rowEdit.ar_is_called == 'N'">
                    <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                    <b-textarea v-model="rowEdit.ar_notes" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control" placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></b-textarea>
                    
                    <VValidate 
                        :name="'Catatan'" 
                        v-model="rowEdit.ar_notes" 
                        :rules="{required: 1, min: 2}"
                    />
                </div>

                <div class="mt-4">
                    <a href="javascript:;" v-if="isRegistration" @click="panggilAntrean(rowEdit)" class="btn btn-info btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-megaphone"></i></b>
                        Panggil Antrean
                    </a>

                    <a href="javascript:;" @click="konfirmasiPanggil()" class="btn btn-success btn-labeled btn-labeled-left">
                    <b><i class="icon-checkmark"></i></b>
                    Konfirmasi
                    </a>
                </div>
                </div>
            </div>
        </b-modal>
        </validation-observer>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
// import draggable from 'vuedraggable'
import $ from 'jquery'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'ar_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'ars_name' },
                { text: 'Nama Poli', value: 'mpo_name' },
                { text: 'Tanggal Daftar', value: 'ar_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Payor', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'cg_label' },
                { text: 'Penunjang', value: 'ar_penunjang' },
            ],
            selectedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            }
        }
    },
  	components:{DateRangePicker},
    methods: {
        panggilAntrean(data){
            data.to = this.uDokterFisio
            this.$socket.emit('panggil_pasien', data)
        },
        doRefreshData(){
            this.apiGet(true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_is_created_rekam_medis !== 'Y'
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_dokter_fisio_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet()
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },100),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(isLoad = false){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isLoad){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+
                '/dokterFisio', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uDokterFisio : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.totalData = resp.dataReg.total
                
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                }   
            })

            // get data master
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/getMaster'
            ).then(res=>{
                let resp = res.data
                this.mPoli = resp.mPoli
            })

            // get total antrian/tindakan
            Gen.apiRest(
                "/get/"+this.modulePage+
                '/getAntrian', 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uDokterFisio : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data                
                this.getQueue = resp.getQueue
                this.getAction = resp.getAction
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'Dashboard'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },
        konfirmasiEdit(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'send-request', id: this.rowEdit.arm_id, arm_can_edit_by: this.user.id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Permintaan Berhasil Dikirim`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet()
                }
                this.openPersetujuan = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openPersetujuan = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        
        requestPanggil(v,isCalled){
            this.rowEdit = v
            this.rowEdit.ar_is_called = 'Y'
            if(isCalled == 'N'){
                 if(this.user.levelId == 3 && v.ar_status == this.sPerawat){
                    this.openPanggil = true
                }else if(this.user.levelId == 5 && v.ar_status == this.sDokter){
                    this.openPanggil = true
                }else if(this.user.levelId == 7 && v.ar_status == this.sFisio){
                    this.openPanggil = true
                }else if((this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 11 || this.user.levelId == 12 || this.user.levelId == 6) && v.ar_status == this.sPPA){
                    this.openPanggil = true
                }else if(this.user.levelId == 1 && v.ar_status == this.sFisio){
                    this.openPanggil = true
                }else{
                    if(this.user.levelId !== 1){
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                    }else{
                        this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uDokterFisio : null } }).catch(()=>{})
                    }
                }
            }else{
                if(this.user.levelId !== 1){
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id }, query: {regId: v.ar_id, byPassLevel: this.user.levelId == 1 ? this.uDokterFisio : null } }).catch(()=>{})
                }
            }
        },
        konfirmasiPanggil(){
            this.$refs['VFormPanggil'].validate().then(success=>{
                if(success){
                    this.rowEdit.type = 'panggil-pasien'
                    this.rowEdit.ar_status = this.sFisio

                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: this.rowEdit}, 
                        "POST"
                    ).then(res=>{
                        if(this.rowEdit.ar_is_called !== 'Y'){
                            
                            if((+this.$route.query.page||1) != 1){
                                this.doResetData()
                            }else{
                                this.apiGet()
                            }

                            this.openPanggil = false

                            this.$swal({
                                title: `Berhasil`,
                                text: 'Berhasil Mengubah Status',
                                icon: 'success',
                            })
                        }else{
                            if(this.user.levelId !== 1){
                                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id} }).catch(()=>{})
                            }else{
                                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.rowEdit.ap_id }, query: {regId: this.rowEdit.ar_id, byPassLevel: this.user.levelId == 1 ? this.uDokterFisio : null } }).catch(()=>{})
                            }
                        }
                    })
                }
            })
        },


        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        approvalEdit(v){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'get-user', id: v.arm_can_edit_by}}, 
                "POST"
            ).then(res=>{
                
                let resp = res.data

                 this.$swal({
                    title: `Setujui Permintaan dari ${resp.row.bu_full_name}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText:  `Ya!`,
                    cancelButtonText:  `Tidak, kembali`,        
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data:{type:'approval', id: v.arm_id, arm_is_approve: 'Y'}}, 
                            "POST"
                        ).then(()=>{
                            this.loadingOverlay = false 
                            this.$swal({
                                title: `Permintaan Berhasil Disetujui`,
                                icon: 'success',
                            })

                            if((+this.$route.query.page||1) != 1){
                                this.doResetData()
                            }else{
                                this.apiGet()
                            }

                        }).catch(err=>{ 
                            this.loadingOverlay = false 
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.message = err.response.data.message
                                err.title = err.response.data.title
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                    }
                })
            })
        },
        
        redirect(v){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: v.ap_id, rmNo: v.arm_id,
            typeKajian:'kajian-dokter-terapi' }, query: {regId: v.ar_id} }).catch(()=>{})
        },
        
        endDrag() {
            Gen.apiRest(
                "/do/" +this.modulePage, {
                data: {
                    type: 'sort-data',
                    data: this.dataReg
                }
                },
                "POST"
            )
        },

        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                        console.log(scrollAmount)
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction(cmp){
            return $(`#${cmp}`).is(':empty')
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_dokter_fisio_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        if(this.user.mpo_id){
            this.filter.poli = this.user.mpo_id
        }

        this.filter.process_status = 'QUEUE'
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)
        
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(){
           this.apiGet()
           setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>