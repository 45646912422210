import { render, staticRenderFns } from "./DashboardDokterGizi.vue?vue&type=template&id=772b426c&scoped=true&"
import script from "./DashboardDokterGizi.vue?vue&type=script&lang=js&"
export * from "./DashboardDokterGizi.vue?vue&type=script&lang=js&"
import style0 from "./DashboardDokterGizi.vue?vue&type=style&index=0&id=772b426c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "772b426c",
  null
  
)

export default component.exports