<template>
  <div class="content-wrapper">
    <div class="page-header">
      <div class="page-header-content header-elements-md-inline">
        <div class="page-title d-flex">
          <h4>
            <span class="font-weight-semibold">Dashboard</span>
          </h4>
          <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>
        <div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">
          <div v-if="isSuper">
            <v-select
              style="min-width: 300px;background-color: #fff;"
              placeholder="Pilih Dashboard"
              class="w-100"
              v-model="selectedDashboard"
              :options="mrSelect"
              label="text"
              @input="changeJenis($event)"
              :reduce="v=>v.value"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content pt-0">
      <DashboardPerawat v-if="isPerawat || selectedDashboard == uPerawat"/>
      <DashboardGeneral v-if="isGeneral"/>
      <DashboardDokter v-if="isDokter || selectedDashboard == uDokter"/>
      <DashboardDokterFisio v-if="isDokterFisio || selectedDashboard == uDokterFisio"/>
      <DashboardDokterGizi v-if="isDokterGizi || selectedDashboard == uDokterGizi"/>

      <DashboardPPAFisio v-if="isFisio || selectedDashboard == uFisio"/>
      <DashboardPPARadiologi v-if="isRadiologi || selectedDashboard == uRadiologi"/>
      <DashboardPPALab v-if="isLab || selectedDashboard == uLab"/>
      <DashboardFarmasi v-if="isFarmasi || selectedDashboard == uFarmasi"/>
      <DashboardRekamMedis v-if="isRekamMedis || selectedDashboard == uRekamMedis"/>
      <DashboardAdmin v-if="isAdmin || selectedDashboard == uAdmin"/>    
      <DashboardAdmisi v-if="isAdmisi || selectedDashboard == uAdmisi"/>      
	  </div>

	
	<b-modal 
		v-model="modalResume"
		:title="'Resume Data'"
		size="xl"
    hide-footer
	>
        <div class="row">
          <div class="col-md-12">
            <div class="wrap_statistic_line">
              <div class="row g-2">
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#162c5e;">
                          <p><i class="icon-users2"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Pendaftar</p>
                        <h4>{{dataResume.total_pendaftar||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#8D7B68;">
                          <p><i class="icon-user-cancel"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>VOID</p>
                        <h4>{{dataResume.total_void||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#DF7857;">
                          <p><i class="icon-cogs"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Dalam Proses</p>
                        <h4>{{dataResume.total_dalam_proses||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#539165;">
                          <p><i class="icon-aid-kit"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Perawat</p>
                        <h4>{{dataResume.total_perawat||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#3E54AC;">
                          <p><i class="icon-aid-kit"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Dokter</p>
                        <h4>{{dataResume.total_dokter||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#DF2E38;">
                          <p><i class="icon-lab"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Laboratorium</p>
                        <h4>{{dataResume.total_lab||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#66347F;">
                          <p><i class="icon-accessibility"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Dietisien</p>
                        <h4>{{dataResume.total_dietisen||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#66347F;">
                          <p><i class="icon-accessibility"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Fisioterapi</p>
                        <h4>{{dataResume.total_fisio_terapi||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#9E4784;">
                          <p><i class="icon-brain"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Radiologi</p>
                        <h4>{{dataResume.total_radiologi||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>

				<div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color: rgb(211, 167, 216);">
                          <p><i class="icon-checkmark2"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Verifikasi Rekam Medis</p>
                        <h4>{{dataResume.total_verif_rm||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="task_wrap">
                    <div class="row align-items-center">
                      <div class="col-md-3 col-3">
                        <a href="javascript:;" class="shape_count" style="background-color:#19A7CE;">
                          <p><i class="icon-user-check"></i></p>
                        </a>
                      </div>
                      <div class="col-md-9 col-9">
                        <p>Selesai</p>
                        <h4>{{dataResume.total_end||"0"}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#539165;">
                      <h3>Perawat</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Antrian</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_queue_perawat||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_perawat||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_perawat||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#3E54AC;">
                      <h3>Dokter</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Antrian</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_queue_dokter||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_dokter||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_dokter||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#DF2E38;">
                      <h3>Laboratorium</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_lab||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_lab||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#66347F;">
                      <h3>Fisioterapi</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_fisio||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_fisio||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#9E4784;">
                      <h3>Radiologi</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_radio||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_radio||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_ds_statistic">
                    <div class="head_wrap" style="background-color:#9E4784;">
                      <h3>Dietisen</h3>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm">
                        <tbody class="detail_bo">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Dalam Tindakan</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_action_dietisen||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Selesai</h4>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <p><strong>{{dataResume.total_end_dietisen||"0"}}</strong></p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" @click="modalResume = false" class="btn btn-link" data-dismiss="modal">Tutup</button>
        </div>
	</b-modal>
  </div>
</template>
<script>

import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import _ from 'lodash'
import DashboardGeneral from './DashboardGeneral.vue'
import DashboardPerawat from './DashboardPerawat.vue'
import DashboardDokter from './DashboardDokter.vue'
import DashboardDokterFisio from './DashboardDokterFisio.vue'
import DashboardDokterGizi from './DashboardDokterGizi.vue'

import DashboardPPAFisio from './DashboardPPAFisio.vue'
import DashboardPPARadiologi from './DashboardPPARadiologi.vue'
import DashboardPPALab from './DashboardPPALab.vue'
import DashboardFarmasi from './DashboardFarmasi.vue'
import DashboardRekamMedis from './DashboardRekamMedis.vue'
import DashboardAdmin from './DashboardAdmin.vue'
import DashboardAdmisi from './DashboardAdmisi.vue'




export default {
 	extends: GlobalVue,
  	components:{DashboardGeneral, DashboardPerawat,DashboardDokter,DashboardDokterFisio, DashboardDokterGizi, DashboardPPAFisio, DashboardPPARadiologi, DashboardPPALab, DashboardFarmasi, DashboardRekamMedis, DashboardAdmin, DashboardAdmisi},
	data() {
    	return {
			modalResume: false,
			dataResume: {},
      sd : null,
      ed : null,
      selectedDashboard: null,
      mrSelect: [
        {
          text : 'Dashboard Perawat',
          value : 3
        },
        {
          text : 'Dashboard Dokter',
          value : 5
        },
        {
          text : 'Dashboard Dokter Fisio',
          value : 7
        },
        {
          text : 'Dashboard Dietisien',
          value : 6
        },
        {
          text : 'Dashboard Lab',
          value : 12
        },
        {
          text : 'Dashboard FisioTerapi',
          value : 9
        },
        {
          text : 'Dashboard Radiologi',
          value : 13
        },
        {
          text : 'Dashboard Farmasi',
          value : 11
        },
        {
          text : 'Dashboard Rekam Medis',
          value : 14
        },
        {
          text : 'Dashboard Admin',
          value : 17
        },
        {
          text : 'Dashboard Admisi',
          value : 18
        }
      ]
		}
	},
	methods: {
    changeJenis(e){
      this.selectedDashboard = e
      this.$router.push({
        name:this.modulePage
      }).catch(()=>{})
    },

		openModalResume(sd = this.sd, ed = this.ed){
			sd = moment(sd).format('YYYY-MM-DD')
			ed = moment(ed).format('YYYY-MM-DD')

      this.sd = sd
      this.ed = ed
			
			this.loadingOverlay = true
			let data = {
                startDate: sd,
                endDate: ed,
                type: 'resume-data'
            }
            Gen.apiRest(
                "/do/"+'Dashboard',
                {data:data}, 
                "POST"
            ).then(res=>{
				let resp = res.data
				this.dataResume = resp.resumeData

				this.loadingOverlay = false
				this.modalResume =  true		
			})

		}
	},
  mounted() {
    if(this.isSuper){
      this.selectedDashboard = 3
    }
  },
	computed:{
		uSuper(){
			return 1
		},
		isSuper(){
			return this.user.levelId == 1
		},

    uPerawat(){
			return 3
		},
		isPerawat(){
			return this.user.levelId == 3
		},

		uDokter(){
			return 5
		},
		isDokter(){
			return this.user.levelId == 5
		},
		
		uDokterFisio(){
			return 7
		},
		isDokterFisio(){
			return this.user.levelId == 7
		},

		uDokterGizi(){
			return 6
		},
		isDokterGizi(){
			return this.user.levelId == 6
		},

		uFisio(){
			return 9
		},
		isFisio(){
			return this.user.levelId == 9
		},

		uLab(){
			return 12
		},
		isLab(){
			return this.user.levelId == 12
		},

		uRadiologi(){
			return 13
		},
		isRadiologi(){
			return this.user.levelId == 13
		},
		
		uFarmasi(){
			return 11
		},
		isFarmasi(){
			return this.user.levelId == 11
		},

		uRekamMedis(){
			return 14
		},
		isRekamMedis(){
			return this.user.levelId == 14
		},

		uAdmin(){
			return 17
		},
		isAdmin(){
			return this.user.levelId == 17
		},

 
		uAdmisi(){
			return 18
		},
		isAdmisi(){
			return this.user.levelId == 18
		},

		isGeneral(){
			let all = [this.uSuper, this.uPerawat, this.uDokter, this.uDokterFisio, this.uDokterGizi, this.uFisio, this.uLab, this.uRadiologi, this.uFarmasi, this.uRekamMedis, this.uAdmin, this.uAdmisi]
			return all.indexOf(this.user.levelId) == -1
		}
	}
}

</script>