<template>
    <div class="dashboard">
      <b-row>
        <b-col md="12">
          <b-card>
            <h4>
              <strong>Howdy Administrator!</strong>
            </h4>
            <p>
              Welcome to the EMR Harapan Hospital Administrator Page
              You are on the PROTECTED page of the EMR Harapan Hospital Content Management system.
            </p>
            <ol>
              <li>Save and secure your Username and Password to avoid things that are not desirable.</li>
              <li>Prepare the material or content that will be updated first.</li>
              <li>Also prepare photos, videos or other related material, to facilitate the updating process.</li>
              <li>Use the latest version of the browser to get the compatibility features in this backoffice.</li>
            </ol>
            <p>
              Web Developer Contact:<br>
              Email:hello@lingkar9.com<br>
              Phone: +62-21-744-6929
            </p>
          </b-card>
        </b-col>
       </b-row>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
// import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            loadingInvinite: false,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,

            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            }
        }
    },
  	// components:{DateRangePicker},
    methods: {
        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },100),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            
            this.doConvertDate()
            
            if((+this.$route.query.page||1) == 1){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+
                '/general', 
                {
                    params: Object.assign({
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.mPoli = resp.mPoli
                this.totalData = resp.dataReg.total
                
                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                }   
            })
        },
        handleScroll(event){
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    this.loadingInvinite = true
                    this.filter.page = (+this.$route.query.page||1) + 1

                    this.$router.push({
                        name:this.modulePage,
                        query:_.clone(this.filter)
                    }).catch(()=>{})
                }
            }
        }
    },
    mounted() {
        this.filter.page = 1
        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
    },  
    created () {
        window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(){
           this.apiGet()
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>